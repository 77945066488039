import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

/*
    Handles everything to do with the shipping option form, including where it send the 
    user if they click local, the look of the entire page, how it works, and the logic behind
    it all.
*/

const ShippingOptionForm = ({nextStep, setLocalButtonPush, localButtonAccess}) => {

    // when the local button is pushed, it will set the local button push variable to true and advance to the next step
    const localButtonDirect = () => {
        setLocalButtonPush(true);
        nextStep();
    }
  return (
    <>
        <Typography variant='h6' gutterBottom>Shipping Options</Typography>
        <Typography variant='subtitle1' gutterBottom>If choosing local, when you click the local button, you will be redirected to a new page. Once you come back, you will be on the shipping form page. In the shipping form, then push local as the shipping option.</Typography>
        <Grid container spacing={3} justifyContent="center">
            <Grid item>
                <Button variant='contained' color='primary' component={Link} to="/cart">Back to Cart</Button>
            </Grid>
            <Grid item>
                {/* Don't want href accesible unless local button access if granted */}
                {localButtonAccess && (
                    <Button variant='contained' color='secondary' onClick={localButtonDirect} disabled={localButtonAccess}>Local</Button>
                )}
                {!localButtonAccess && (
                    <a href="https://calendly.com/shescraftycrafty/local-pickups" target="_blank" rel='noreferrer' style={{textDecoration: 'none'}}>
                        <Button variant='contained' color='secondary' onClick={localButtonDirect} disabled={localButtonAccess}>Local</Button>
                    </a>
                )}
            </Grid>
            <Grid item>
                <Button variant='contained' color='primary' onClick={nextStep} >Next</Button>
            </Grid>
        </Grid>
    </>
  )
}

export default ShippingOptionForm