import React, {useState} from 'react'
import { Typography, Button, Divider } from '@material-ui/core';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Review from './Review';

/*
  Handles everything to do with the payment form including the Stripe information,
  all the information that needs to be sent to the Review page, and the logic behind it all.
  The Review page handles all of the items on the form, but this page handles the payment, and
  then generating the order from all the information present.
*/

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({ checkoutToken, shippingData, nextStep, backStep, onCaptureCheckout, shippingOptionName, shippingOptionPrice, setCheckoutToken, activeStep }) => {

  const [pushButtons, setPushButtons] = useState(true);
  const [buttonTimerCounter, setButtonTimerCoutner] = useState(0);
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  const [code, setCode] = useState('');

  /* 
    Buttom timers added due to tax being updated in real time when
    the user lands on the payment form.
  */

  var timerTime = 3 // <- in seconds
  var secondCount = 0

  // sets interval every second
  setInterval(function (){
      setSecondsElapsed(secondCount++)
      buttonCountdown()
  }, 1000);

  // check to make sure the timer logic is being followed 
  function buttonCountdown() {
      // check to see if we can push the buttons
      if (buttonTimerCounter === 0 && secondsElapsed >= timerTime) {
          setPushButtons(false)
          setButtonTimerCoutner(1)
      }
  }

  // what happpens when the payment form is submitted
  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();

    // if the necessary elements are not present, early return out of the function
    if(!stripe || !elements) return;

    // gets the card element to be used later
    const cardElement = elements.getElement(CardElement);

    // creates the payment method based on the card information present
    const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card: cardElement})

    // check to see if an error has occured thus far
    if(error)
      console.log(error);
    // if not, then the order data is set based on the information provided
    else {
      const orderData = {
        line_items : checkoutToken.line_items,
        customer : {
          firstname : shippingData.firstName, 
          lastname : shippingData.lastName, 
          email: shippingData.email},
        shipping : {
          name: 'Primary', 
          street: shippingData.address1,
          town_city: shippingData.city,
          county_state: shippingData.shippingSubdivision,
          postal_zip_code: shippingData.zip,
          country: shippingData.shippingCountry,
        },
        fulfillment : {shipping_method : shippingData.shippingOption},
        payment: {
          gateway : 'stripe',
          stripe : {
            payment_method_id : paymentMethod.id
          }
        }
      }
      // all the necessary data is sent to create the order
      onCaptureCheckout(checkoutToken.id, orderData);
      
      // the form is advanced one step
      nextStep();
    }
  }
  
  return (
    <>
      <Review checkoutToken={checkoutToken} shippingOptionName={shippingOptionName} shippingOptionPrice={shippingOptionPrice} setCode={setCode} code={code} setCheckoutToken={setCheckoutToken} pushButtons={pushButtons} />
      <Divider />
      <Typography variant='h6' gutterBottom style={{ margin: '20px 0'}}></Typography>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
              <CardElement />
              <br /><br />
              <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Button variant='outlined' onClick={backStep} disabled={pushButtons}>Back</Button>
                <Button type='submit' variant='contained' disabled={!stripe || pushButtons} color="primary">
                  Pay {checkoutToken.total_with_tax.formatted_with_symbol}
                </Button>
              </div>
            </form>
          )}
        </ElementsConsumer>
      </Elements>
    </>
  )
}

export default PaymentForm