import React from "react";
import { makeStyles} from '@material-ui/core/styles'
import { InputLabel, FormControl, Select, MenuItem, ListItemText, Checkbox, OutlinedInput } from "@material-ui/core";

/*
    Handles the Dropdown menu for the Navbar, which helps seperate the categories
    of products to make it easier for the user to see what products they want.
*/

// custom style per there already being a styles.js file in this folder
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const Dropdown = ({products, category, setCategory}) => {
    const classes = useStyles();

    // container for the categories to go into
    let categoryList = []

    // this will get all of the categories every time, just in case they change
    for (let p of products)
        categoryList.push(p.categories[0].name)

    // sets the category based on the option the user selected
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setCategory(
            typeof value === "string" ? value.split(",") : value,
        );
    };
    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-cat" style={{ marginLeft: 13, marginTop: -6}}>Sort</InputLabel>
                <Select
                    labelId="select-multiple-cat-check-label"
                    id="select-multiple-cat-check"
                    multiple
                    value={category}
                    onChange={handleChange}
                    label="categories"
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {categoryList.map((cat) => (
                        <MenuItem key={cat} value={cat}>
                            <Checkbox checked={category.indexOf(cat) > -1} />
                            <ListItemText primary={cat} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default Dropdown