import { makeStyles } from '@material-ui/core/styles';
import { Autorenew } from '@material-ui/icons';

/*
  Handles all the styles for the CartItem.jsx.
*/

export default makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 260,
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  cartActions: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  price: {
    [theme.breakpoints.up('lg')]: {
      marginRight: '30px',
    },
  },

}));