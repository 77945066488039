import React, { useState, useEffect } from 'react'
import useStyles from './styles';
import { Typography, Button, Grid, Paper } from '@material-ui/core'
import FormInput from './CustomTextField'
import { useForm, FormProvider} from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../../lib/firebase';
import { ref, update, onValue } from 'firebase/database';

/*
    Handles everything to do with updating the profile information of the user,
    whether that be the look of the page, how it works, or the logic of it all.
*/

const UpdateInfo = ({ currentUser }) => {
    const classes = useStyles();
    const methods = useForm();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZIP] = useState('');

    // updates the information in the database with the data provided
    const updateDB = () => {
        update((ref(db, 'users/' + currentUser?.uid)), {
            firstName : firstName,
            lastName : lastName,
            address : address,
            city : city,
            zip : zip
        })
        // after updating, sent back to the profile page
        navigate("/profile");
    }

    // gets all of the user's information from the database and set's it accordingly
    useEffect(() => {
        onValue(ref(db, 'users/' + currentUser?.uid), (snapshot) => {
            setFirstName(snapshot.val() && (snapshot.val().firstName || " "))
            setLastName(snapshot.val() && (snapshot.val().lastName || " "))
            setAddress(snapshot.val() && (snapshot.val().address || " "))
            setCity(snapshot.val() && (snapshot.val().city || " "))
            setZIP(snapshot.val() && (snapshot.val().zip || " "))
        })
    }, [currentUser?.uid]) 

    return (
        <>
            <div className={classes.toolbar} />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant='h4' align='center' gutterBottom>Update Information</Typography>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit((data)=> ({ ...data}))}>
                            <Grid container spacing={3} justifyContent="center">
                                    <Grid container item spacing={3} justifyContent="center">
                                        <FormInput name='firstName' label='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                    </Grid>
                                    <Grid container item spacing={3} justifyContent="center">
                                        <FormInput name='lastName' label='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                    </Grid>
                                    <Grid container item spacing={3} justifyContent="center">
                                        <FormInput name='address' label='Address' value={address} onChange={(e) => setAddress(e.target.value)}/>
                                    </Grid>
                                    <Grid container item spacing={3} justifyContent="center">
                                        <FormInput name='city' label='City' value={city} onChange={(e) => setCity(e.target.value)}/>
                                    </Grid>
                                    <Grid container item spacing={3} justifyContent="center">
                                        <FormInput name='zip' label='ZIP / Postal Code' value={zip} onChange={(e) => setZIP(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <Button component={Link} to="/profile" variant="contained" color='secondary'>Back to Profile</Button>
                                    <Button onClick={updateDB} variant="contained" color='primary'>Update</Button>
                                </div>
                        </form>
                    </FormProvider>
                </Paper>
            </main>
        </>
    )
}

export default UpdateInfo