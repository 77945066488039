import React, { useState, useEffect } from 'react'
import { InputLabel, Select, MenuItem, Button, Grid, Typography } from '@material-ui/core';
import { useForm, FormProvider} from 'react-hook-form';
import { ref, onValue } from 'firebase/database';
import { db } from '../../lib/firebase';

import { commerce } from '../../lib/commerce';

import FormInput from './CustomTextField';

/*
    Handles everything to do with the address form, whether 
    that collecting all the necessary variables, setting timers, getting 
    shipping details, how the page looks, or the logic behind it all.
*/

const AddressForm = ({ checkoutToken, next, backStep, currentUser, localButtonPush, activeStep }) => {
    const [shippingCountries, setShippingCountries] = useState([]);
    const [shippingCountry, setShippingCountry] = useState('');
    const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
    const [shippingSubdivision, setShippingSubdivision] = useState('');
    const [shippingOptions, setShippingOptions] = useState([]);
    const [shippingOption, setShippingOption] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('')
    const [address1, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZIP] = useState('');
    const methods = useForm();
    const [timeActive, setTimeActive] = useState(false);
    const [time, setTime] = useState(2);
    const [pushButtons, setPushButtons] = useState(true);
    const [buttonTimerCounter, setButtonTimerCoutner] = useState(0);
    const [secondsElapsed, setSecondsElapsed] = useState(0);

    /*
        This snippet handles when the user naviagates away from the
        page, solving the weird array error. Might include a timer 
        at some point, but for right now, we will have it when the user
        navs away from the page.

        Come back to this if problem persists
    */

    /* 
        This code snippet is for a timer when changing countries,
        subdivisions, or shipping options
    */

    // used to count the timer down or reset based on the time variable
    useEffect(() => {
        let interval = null;
        if(timeActive && time !== 0){
            // counts the time down by the second
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        // resets everything
        } else if(time === 0){
            setTimeActive(false)
            setTime(2)
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time, setTimeActive])

    // changes the country and restarts the timer
    const changeCountry = (e) => {
        changeCountryProcess(e)
        setTimeActive(true)
    }

    // changes the subdivision and restarts the timer
    const changeSubdivision = (e) => {
        setShippingSubdivision(e)
        setTimeActive(true)
    }

    // changes the shipping option and restarts the timer
    const changeShipping = (e) => {
        setShippingOption(e)
        setTimeActive(true)
    }

    /*
        This section is for when we load in the page and we don't
        want the user to click any of the buttons yet. We are going
        to set a timer for 5-6 seconds to account for the load times
        of the shipping variables, and then the user can push the button. 
        We want to do this everytime the page is loaded, and only at the
        beginning, so we will have a counter variable that will be reset
        when the page is advanced, if it is reloaded, or if one of the 
        shipping options is changed.
    */
    var timerTime = 9 // <- in seconds
    var secondCount = 0

    // sets interval every second
    setInterval(function (){
        setSecondsElapsed(secondCount++)
        buttonCountdown()
    }, 1000);

    // check to make sure the timer logic is being followed 
    function buttonCountdown() {
        // check to see if we can push the buttons
        if (buttonTimerCounter === 0 && secondsElapsed >= timerTime) {
            setPushButtons(false)
            setButtonTimerCoutner(1)
        }

    }

    // this is used to check for when the buttons should in inactive
    useEffect(() => {
        /*
            Checks to see if the window is reloaded, if the active step is 
            not on the current page, or the time is active. If so, we want to 
            disable the ability to push the buttons.
        */
        if((window.PerformanceNavigationTiming && window.PerformanceNavigationTiming.type === 1) || activeStep !== 1 || timeActive) {
            setButtonTimerCoutner(0);
            setSecondsElapsed(0);
            setPushButtons(true)
        }
    }, [activeStep, timeActive])


    // gets all the options in the designated format
    const countries = Object.entries(shippingCountries).map(([code, name]) => ({id: code, label: name}))
    const subdivisions = Object.entries(shippingSubdivisions).map(([code, name]) => ({id: code, label: name}))
    const options = shippingOptions.map((sO) => ({id: sO.id, label: sO.description  + ' - ' + sO.price.formatted_with_symbol}))
    const optionsFiltered = options

    /*
        Gets all the shipping countries from Commerce.JS, sets them as the 
        countries available, and sets the first country from the list as 
        the first option of the form.
     */
    const fetchShippingCountries = async (checkoutTokenId) => {
        const { countries } = await commerce.services.localeListShippingCountries(checkoutTokenId);
        setShippingCountries(countries);
        setShippingCountry(Object.keys(countries)[0]);
    }

    /*
        Gets all the shipping subdivisions from Commerce.JS, sets them as the 
        subdivisions available, and sets the first subdivision from the list as 
        the first option of the form.
     */
    const fetchSubDivisions = async (countryCode) => {
        const { subdivisions } = await commerce.services.localeListSubdivisions(countryCode);
        setShippingSubdivisions(subdivisions);
        setShippingSubdivision(Object.keys(subdivisions)[0]);
    }

    /*
        Gets all the shipping options from Commerce.JS, sets them as the 
        options available, and sets the first option from the list as 
        the first option of the form.
     */
    const fetchShippingOptions = async (checkoutTokenId, country, region = null) => {
        const options = await commerce.checkout.getShippingOptions(checkoutTokenId, { country, region });
        setShippingOptions(options);
        setShippingOption(options[0].id);
    }

    // fetches the shipping countries on the render of the page
    useEffect(() => {
        fetchShippingCountries(checkoutToken.id)
    }, [checkoutToken.id]);

    // once the country has been rendered, fetch the subdivision
    useEffect(() => {
        if(shippingCountry) fetchSubDivisions(shippingCountry)
    }, [shippingCountry]);

    // once the subdivision has been rendered, fetch the shipping options
    useEffect (() => {
        if(shippingSubdivision) fetchShippingOptions(checkoutToken.id, shippingCountry, shippingSubdivision);
    }, [checkoutToken.id, shippingCountry ,shippingSubdivision]);

    // gets all of the user's information from the database and set's it accordingly
    useEffect(() => {
        onValue(ref(db, 'users/' + currentUser?.uid), (snapshot) => {
            setFirstName(snapshot.val() && (snapshot.val().firstName || " "))
            setLastName(snapshot.val() && (snapshot.val().lastName || " "))
            setEmail(snapshot.val() && (snapshot.val().email || " "))
            setAddress(snapshot.val() && (snapshot.val().address || " "))
            setCity(snapshot.val() && (snapshot.val().city || " "))
            setZIP(snapshot.val() && (snapshot.val().zip || " "))
        })
    }, [currentUser?.uid]) 

    // handles process of changing countries in the form
    const changeCountryProcess = (e) => {
        /* 
           This is due to an error being thrown due to 
           the subdivsions not matching up when the new 
           country is selected.
        */
        setShippingSubdivision('');
        setShippingSubdivisions([]);
        setShippingCountry(e);
    }

    return (
        <>
            <Typography variant='h6' gutterBottom>Shipping Address</Typography>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data)=> next({ ...data, firstName, lastName, email, address1, city, zip, shippingCountry, shippingSubdivision, shippingOption }))}>
                    <Grid container spacing={3}>
                        <FormInput name='firstName' label='First Name' value = {firstName} set={setFirstName}/>
                        <FormInput name='lastName' label='Last Name' value = {lastName} set={setLastName}/>
                        <FormInput name='address1' label='Address' value = {address1} set={setAddress}/>
                        <FormInput name='email' label='Email' value = {email} set={setEmail}/>
                        <FormInput name='city' label='City' value = {city} set={setCity}/>
                        <FormInput name='zip' label='ZIP / Postal Code' value = {zip} set={setZIP}/>
                        <Grid item  xs={12} sm={6}>
                            <InputLabel>Shipping Country</InputLabel>
                            <Select required value={shippingCountry === undefined ? "": shippingCountry} fullWidth onChange={(e) => changeCountry(e.target.value)} disabled={timeActive}>
                                {countries.map((country) => (
                                    <MenuItem key={country.id} value={country.id}>{country.label}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <InputLabel>Shipping Subdivision</InputLabel>
                            <Select required value={ shippingSubdivision === undefined ? "" : shippingSubdivision } fullWidth onChange={(e) => changeSubdivision(e.target.value)} disabled={timeActive}>
                                {subdivisions.map((subdivision) => (
                                    <MenuItem key={subdivision.id} value={subdivision.id}>{subdivision.label}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <InputLabel>Shipping Options</InputLabel>
                            <Select required value={shippingOption === undefined ? "" : shippingOption} fullWidth onChange={(e) => changeShipping(e.target.value)} disabled={timeActive}>
                                {/* if shipping country is in the united states and in tennessee, want to make local option available */}
                                {options.filter(option => option.label !== "Local - $5.00").map((option) => (
                                    // if the option is this, show local option, otherwise, let it be filtered out
                                    shippingCountry === "US" && shippingSubdivision === "TN" && localButtonPush
                                    ? ( optionsFiltered.map((optionF) => (
                                        <MenuItem key={optionF.id} value={optionF.id}>{optionF.label}</MenuItem>
                                    )))
                                    : (<MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={backStep} variant="outlined" disabled={pushButtons}>Back</Button>
                        <Button type='submit' variant="contained" color='primary' disabled={pushButtons}>Next</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default AddressForm