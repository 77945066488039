/* 
  Handles the EmailJS Configuration.
*/

const emailConfig = {
  serviceId: process.env.REACT_APP_EMAIL_SERVICE_ID,
  templateId: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
  userId: process.env.REACT_APP_EMAIL_USER_ID
};

export {emailConfig}