import { makeStyles } from "@material-ui/core/styles";

/*
  Handles all the styles for the Product.jsx.
*/

export default makeStyles(() => ({
    root: {
        maxWidth: '100%',
    },
    media : {
        height: 0,
        paddingTop: '56.25%',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardContent : {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));