import React, { useState, useRef } from 'react'
import useStyles from './styles'
import {Typography, Paper, Button, TextField} from '@material-ui/core';
import { useForm, FormProvider} from 'react-hook-form';
import FormInput from './CustomTextField';
import emailjs from 'emailjs-com';
import {emailConfig} from '../../lib/email';

/*
    Handles everything to do with the Footer, whether that be the form,
    email, way it looks, or the logic behind it all.
*/

const Footer = () => {
    const classes = useStyles();
    const methods = useForm();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [resMessage, setResMessage] = useState('');
    const form = useRef();

    // resets the error message when the send button is pushed
    const resetMessage = () => {
        setResMessage("");
    }

    // resets the form, so it looks like the message was sent, and in case another message needs to be sent
    const resetForm = () => {
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
    }

    // sets the display message to an error
    const getError = () => {
        setResMessage("Error: Please Try Again.")
    }

    // checks and sets the display message to a success message
    const getResult =  (RMessage) => {
        if (RMessage === 'OK')
        {
            setResMessage("Email has been Sent!")
        }
    }

    // handles what happens when the email form is submitted
    const sendEmail = (e) => {
        e.preventDefault();

        // attempts to send the email to the client with all the information
        emailjs.sendForm(emailConfig.serviceId, emailConfig.templateId, form.current, emailConfig.userId)

        // if success, the display message shows the success message
        .then((result) => {
            getResult(result.text);
        // if error, display message shows the error message
        }, (error) => {
            getError();
        });

        // resets the form for the next message and to show that the last one was sent
        resetForm()
    };
    return (
        <>
        <div className={classes.toolbar} />
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography align='center'>Contact Us</Typography>
                <FormProvider {...methods}>
                    <form ref={form} onSubmit={sendEmail}>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <FormInput name='user_name' variant='outlined' label='Name' value={name} onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <FormInput name='user_email' variant='outlined' label='Email address' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <FormInput name='subject' variant='outlined' label='Subject of Email' value={subject} onChange={(e) => setSubject(e.target.value)}/>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <TextField name='message' multiline fullWidth minRows={4} variant='outlined' label='Enter Message Here' value={message} onChange={(e) => setMessage(e.target.value)}/>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <Button type='submit' variant="contained" color='primary' onClick={resetMessage}>Send</Button>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <Typography>{resMessage}</Typography>
                        </div>
                    </form>
                </FormProvider>
            </Paper>
        </main>
        </>
    )
}

export default Footer