import React from 'react'
import { TextField, Grid } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';

/*
    Creates a CustomTextField for the UpdateInfo.jsx file.
*/

const FormInput = ({ name, label, type, value, onChange }) => {
    const { control } = useFormContext();
    return (
        <Grid item xs={12} sm={6}>
            <Controller
                defaultValue=""
                control={control}
                name={name}
                 
                render = {({ field }) => (
                    <TextField
                        fullWidth
                        label={label}
                        type ={type}
                        value = {value}
                        onChange={onChange}
                        required
                    />
                )}
                
            />

        </Grid>
    )
}

export default FormInput