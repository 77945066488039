import React, { useState, useEffect } from 'react'
import { Paper, Stepper, Step, StepLabel, Typography, CircularProgress, Divider, Button, CssBaseline} from '@material-ui/core';
import { commerce } from '../../../lib/commerce';
import useStyles from './styles';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import ShippingOptionForm from '../ShippingOptionForm';
import { Link, useNavigate } from 'react-router-dom';

/*
    This handles the entire checkout process of the storefront. This handles the 3 forms through
    the implementation of an active step counter, and uses a confirmation step when the order has gone
    through. Amongst other things, it handles states for many variables that go between the three forms,
    setting the order information, setting when the form continues or when the user can go back, custom
    additions to the checkout object, and much more.
*/

// holds the names of the forms to be displayed at the top of each page along with the step associated
const steps = ['Shipping options', 'Shipping address', 'Payment details'];

const Checkout = ({ cart, order, onCaptureCheckout, error, currentUser, refreshCart, setASCounter, localButtonAccess, orderErrorOccurred }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [checkoutToken, setCheckoutToken] = useState(null);
    const [shippingData, setShippingData] = useState({});
    const [localButtonPush, setLocalButtonPush] = useState(false);
    const [shippingOptionName, setShippingOptionName] = useState('');
    const [shippingOptionPrice, setShippingOptionPrice] = useState('');
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [errorMess, setErrorMess]= useState('');
    const [counter, setCounter] = useState(0)
    const classes = useStyles();
    const navigate = useNavigate();

    // tries to generate the checkout object based on the cart at the given time
    useEffect(() => {
        const generateToken = async () => {
            try {
                const token = await commerce.checkout.generateToken(cart.id, { type : 'cart'})
                setCheckoutToken(token);
            // if there is an error, it navigates the user back to the home page
            } catch (error) {
                navigate("/")
            }
        }

        // calls the function upon every render
        generateToken();
    }, [cart, navigate]);

    // gives helper functions to handle when the user needs to advance or go back throughout the checkout process
    const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // used within the address form, sets the shipping data, and goes to the next step
    const next = (data) => {
        setShippingData(data);
        nextStep();
    }

    // used on the confirmation page, it refreshes the cart, and then reloads the window
    const backHome = () => {
        refreshCart();
        // this causes the user to be taken back to the home page since no cart is found
        window.location.reload();
    }

    // sets the active step counter if the user is on the confirmation page
    useEffect(() => {
        if(activeStep === 3)
            setASCounter(1);
    })

    // reset cart in the event the user pushes the refresh button on the confirmation page
    useEffect(() => {
        // if on the confirmation page
        if(activeStep === 3)
            // check to see if the page has been refreshed
            if(window.PerformanceNavigationTiming && window.PerformanceNavigationTiming.type === 1)
                // if so, refresh cart
                refreshCart();
    })

    // handles the confirmation page of the checkout process
    let Confirmation = () => order.customer ? (
        <>
        <CssBaseline/>
            <div>
                <Typography variant='h5'>Thank you for your purchase, {order.customer.firstname} {order.customer.lastname}</Typography>
                <Divider className={classes.divider}/>
                <Typography variant='subtitle2'>Order reference #: {order.customer_reference}</Typography>
            </div>
            <br/>
            <Button component={Link} to="/" onClick={backHome} variant='outlined' type='button'>Back to Home</Button>
        </>
    ) : (
        <div className={classes.spinner}>
            <CircularProgress/>
        </div>
    );

    // if an error occurrs, show this screen
    if(orderErrorOccurred)(
        <>
            <div className={classes.toolbar} />
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography variant='h5' align='center' color='error'>An Error Has Occured: {error}</Typography>
                        <br/>
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <Button component={Link} to="/" variant='contained' type='button' color='primary'>Back to Home Screen</Button>
                        </div>
                    </Paper>
                </main>
            </>
    )

    // updating the checkout token to reflect the shipping option
    useEffect(() => {
        // checking to make sure we are on the payment page
        if(activeStep === 2) {
            // need to go through the checkout token and shipping data and see which id matches
            // iterate through the checkout token first
            for (const [key, value] of Object.entries(checkoutToken)) {
                // checking for shipping methods
                if (key === "shipping_methods")
                    // go through each shipping option
                    for (let si of value) {
                        // check and see if any of the shipping options from the checkout token match the shipping data
                        if(si.id === shippingData["shippingOption"]) {
                            // if so, we want to update the price of shipping to the found shipping category
                            
                            // set shipping option name to be used within the review page
                            setShippingOptionName(si.description)

                            // sets the checkout object's price to the correct price
                            checkoutToken["shipping"]["price"] = si.price

                            // set the shipping option price to be used within the review page
                            setShippingOptionPrice(si.price.formatted_with_symbol)
                        }
                    }
              }

        }
    }, [activeStep, checkoutToken, shippingData])
    
    /*
        This useEffect will handle updating the total and total due from the 
        new shipping information. If we go back a step, we will want to reset 
        the counter and the variables.
    */
    useEffect(() => {
        // only want run once
        if(counter === 0) {
            // only want this to run on the last step
            if (activeStep === 2) {
                // want this to only run once
                if (counter === 0) {
                    // update the total variables
                    checkoutToken["total"]["raw"] = checkoutToken["total"]["raw"] + checkoutToken["shipping"]["price"]["raw"]

                    // locale string is used to input the commas when thousands are used
                    checkoutToken["total"]["formatted"] = `${(checkoutToken["total"]["raw"]).toLocaleString("en-US")}.00`
                    checkoutToken["total"]["formatted_with_code"] = `${checkoutToken["total"]["formatted"]} USD`
                    checkoutToken["total"]["formatted_with_symbol"] = `$${checkoutToken["total"]["formatted"]}`
                    
                    // update the total_due variables
                    checkoutToken["total_due"] = checkoutToken["total"]

                    // make sure this is not ran again
                    setCounter(1);
                }
            }
        }
        // reset everything
        else if (activeStep < 2 && counter === 1) {
            setCounter(0);
            checkoutToken["total"]["raw"] = checkoutToken["total"]["raw"] - checkoutToken["shipping"]["price"]["raw"]
            checkoutToken["total"]["formatted"] = `${checkoutToken["total"]["raw"]}.00`
            checkoutToken["total"]["formatted_with_code"] = `${checkoutToken["total"]["formatted"]} USD`
            checkoutToken["total"]["formatted_with_symbol"] = `$${checkoutToken["total"]["formatted"]}`
            checkoutToken["total_due"] = checkoutToken["total"]
        }
        
    }, [activeStep, counter, checkoutToken])

    // this is used to set the correct tax zone, so tax can be applied
    useEffect(() => {
        // only want ran on the payment form page
        if (activeStep === 2) {
            // attempts to set the tax zone with the supplied data
            commerce.checkout.setTaxZone(checkoutToken.id, {
                country: shippingData.shippingCountry,
                region: shippingData.shippingSubdivision,
                postal_zip_code: shippingData.zip,
            // if success, sets the checkout object to the newly generated object
            }).then((response) => {setCheckoutToken(response)})
              .catch((error) => {handleError(error)})
        }
        
      }, [activeStep, shippingData.shippingCountry, shippingData.shippingSubdivision, shippingData.zip])

    
    // set's the conditional to true for rendering and sets the object for customization
    const handleError = (error) => {
        setErrorOccurred(true); 
        setErrorMess(error.data.error.message);
    }

    // error page for when something is not right with the address
    const ErrorPage = ({errorMess}) => {
        // happens when address is bad or inaccurate
        return (
            <>
            <div className={classes.toolbar} />
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography variant='h5' align='center' color='error'>An Error Has Occured: {errorMess}</Typography>
                        <br/>
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <Button component={Link} to="/" variant='contained' type='button' color='primary'>Back to Home Screen</Button>
                        </div>
                    </Paper>
                </main>
            </>
        )
    }

    // displays the error page when an error occurs
    if (errorOccurred) {
        return <ErrorPage errorMess={errorMess}/>
    }
     
    // handles all the routing between the different forms based on the active steps
    const Form = () => activeStep === 0
        ? <ShippingOptionForm nextStep={nextStep} setLocalButtonPush={setLocalButtonPush} localButtonAccess={localButtonAccess}/>
        : ( activeStep === 1 ? <AddressForm checkoutToken={checkoutToken} next={next} backStep={backStep} currentUser={currentUser} localButtonPush={localButtonPush} activeStep={activeStep}/> 
        : <PaymentForm shippingData={shippingData} checkoutToken={checkoutToken} nextStep={nextStep} backStep={backStep} onCaptureCheckout={onCaptureCheckout} shippingOptionName={shippingOptionName} shippingOptionPrice={shippingOptionPrice} setCheckoutToken={setCheckoutToken} activeStep={activeStep} />)    
   
  return (
    <>
        <div className={classes.toolbar} />
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography variant='h4' align='center'>Checkout</Typography>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((step) => (
                        <Step key={step}>
                            <StepLabel>{step}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? <Confirmation /> : checkoutToken && <Form />}
            </Paper>
        </main>
    </>
  )
}

export default Checkout