import { makeStyles,  } from '@material-ui/core/styles';

/*
  Handles all the styles for the Footer.jsx.
*/

export default makeStyles((theme) => ({
    footer: {
        position: 'sticky',
        top: '100%'
    },
    body: {
        minHeight: '100vh',
        position: 'absolute',
        alignContent: 'center'
    },
    layout: {
        marginTop: '5%',
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
          marginRight: theme.spacing(8)
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginTop: 60,
          padding: theme.spacing(3),
        },
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
      },
}));