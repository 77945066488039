import React, { useState, useEffect} from 'react'
import { Typography, Paper, Button } from "@material-ui/core";
import { sendEmailVerification } from 'firebase/auth';
import useStyles from './styles';
import { useNavigate } from 'react-router-dom';

/*
    Handles rendering and logic for the verify email page. This handles when the email
    is sent to the user, when the user can get another verification email sent, and how
    it looks in the storefront.
*/

const VerifyEmail = ({ currentUser, timeActive, setTimeActive }) => {
    const classes = useStyles();
    const [time, setTime] = useState(60);
    const navigate = useNavigate();
    
    /*
        Checks to see if the currentUser is verified. If so, then it reroutes to the profile page.
        If not, it will alert with the error.
    */
    useEffect(() => {
        const interval = setInterval(() => {
            // get the most up-to-date user
            currentUser?.reload()
            .then(() => {
                // check to see if verified
                if(currentUser?.emailVerified)
                {
                    clearInterval(interval)
                    navigate('/profile')
                }
            })
            .catch((err) => {
                alert(err.message)
            })
        }, 1000) // runs every second
    }, [navigate, currentUser])


    /*
        Sets a timer for when the user can send another verification email to the email they
        provided in the sign up form.
    */
    useEffect(() => {
        let interval = null;
        if(timeActive && time !== 0){
            interval = setInterval(() => {
                // subtracts one second from the time
                setTime((time) => time - 1)
            }, 1000) // runs every second
        } else if(time === 0){
            setTimeActive(false)
            setTime(60)
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time, setTimeActive])

    // sends the email verification to the user's email and starts the timer
    const resendEmailVerification = () => {
        sendEmailVerification(currentUser)
        .then(() => {
            setTimeActive(true)
        }).catch((err) => {
            alert(err.message)
        })
    }

    return (
        <>
            <div className={classes.toolbar} />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant='h4' align='center' gutterBottom>Verify Email</Typography>
                    <Typography variant='subtitle1' gutterBottom>A verification email has been sent to: {currentUser?.email}</Typography>
                    <Typography variant='subtitle1' gutterBottom>Follow the instuctions in the email to verify your account</Typography>
                    <Typography align='center'><Button onClick={resendEmailVerification} disabled={timeActive} variant="contained" color='primary'>Resend Email {timeActive && time}</Button></Typography>
                </Paper>
            </main>
        </>
    )
}

export default VerifyEmail