import React, { useState } from 'react'
import { Typography, IconButton, Button, Grid, Paper } from '@material-ui/core'
import { Visibility } from '@material-ui/icons';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../../../lib/firebase';
import { useForm, FormProvider} from 'react-hook-form';
import FormInput from '../CustomTextField';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { ref, set } from 'firebase/database';

/*
    This handles everything to do with the Signup page: how it looks
    how it works, and the logic behind it all.
*/

const Signup = ({ timeActive, setTimeActive}) => {
    const methods = useForm();
    const classes = useStyles();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZIP] = useState('');
    const [toggle, setToggle] = useState(true);
    const [ctoggle, setCToggle] = useState(true);
    const [em, setEm] = useState('');
    
    // makes sure that both passwords are the same
    const validatePassword = () => {
        let isValid = true
        // on intial load in, both are blank and don't want this to fire off
        if (password !== '' && confirmPassword !== ''){
            // if they don't match, not valid
            if (password !== confirmPassword){
                isValid = false
                setEm("Passwords do not match")
            }
        }
        return isValid
    }

    // custom error message handling to make it more user-friendly
    const getError = (errorMessage) => {
        if(errorMessage === 'Firebase: Error (auth/invalid-email).')
            setEm("Invalid Email: Please Enter a Valid Email.")
        else if (errorMessage === 'Firebase: Error (auth/wrong-password).')
            setEm("Invalid Password: Please Enter A Valid Password.")
        else if (errorMessage === 'Firebase: Password should be at least 6 characters (auth/weak-password).')
            setEm("Invalid Password: Password Should be at Least 6 Characters.")
        else
            setEm("Error: Please Try Again.")
    }

    // writes the user's data to the Firebase Database
    const writeUserData = (userId, email, firstName, lastName, address, city, zip) => {
        set(ref(db, 'users/' + userId), {
            firstName : firstName,
            lastName : lastName,
            email : email,
            address : address,
            city : city,
            zip : zip
        });
    }

    // handles what happens when the submit button is pushed on the form through the website
    const onSubmit = async (e) => {
        e.preventDefault();
        // check to see if both the passwords match
        if(validatePassword()){
            // attempts to create a user within the authentication database with the email and password
            await createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    //signed in
                    const user = userCredential.user;

                    // write user data to the user database
                    writeUserData(user?.uid, email, firstName, lastName, address, city, zip)

                    // verify email and send to verify email page
                    sendEmailVerification(user)
                    .then(() => {
                        setTimeActive(true)
                        navigate("/verify-email")
                    }).catch((err) => alert(err.message))   
                })
                // if something is wrong, the error is displayed to the user
                .catch((error) => {
                    const errorMessage = error.message;
                    getError(errorMessage);
                })
            }
    }

    // set's the password to visible or not visbile, based on what the user wants
    const toggler = () => {
        toggle ? setToggle(false) : setToggle(true)
    }

    // set's the confirmation password to visible or not visbile, based on what the user wants
    const ctoggler = () => {
        ctoggle ? setCToggle(false) : setCToggle(true)
    }
    
  return (
    <>
        <div className={classes.toolbar} />
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography variant='h4' align='center' gutterBottom>Sign up</Typography>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((data)=> ({ ...data}))}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='firstName' label='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='lastName' label='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='email' label='Email address' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='address' label='Address' value={address} onChange={(e) => setAddress(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='city' label='City' value={city} onChange={(e) => setCity(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='zip' label='ZIP / Postal Code' value={zip} onChange={(e) => setZIP(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <IconButton disabled='true'/>
                                <IconButton disabled='true'/>
                                <FormInput name='password' label='Password' type= {toggle ? 'password' : " "} value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <IconButton onClick={toggler}>
                                    <Visibility/>
                                </IconButton>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <IconButton disabled='true'/>
                                <IconButton disabled='true'/>
                                <FormInput name='confirmPassword' label='Confirm Password' type= {ctoggle ? 'password' : " "} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                                <IconButton onClick={ctoggler}>
                                    <Visibility/>
                                </IconButton>
                            </Grid>
                            <Grid>
                                <Typography color='error'>{em}</Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <Button component={Link} to="/" variant="contained" color='secondary'>Back to Home </Button>
                            <Button type='submit' onClick={onSubmit} variant="contained" color='primary'>Sign up</Button>
                        </div>
                        <Typography variant='h6' align='center' gutterBottom>Already made an account?</Typography>
                        <Typography align='center'><Button component={Link} to='/login' variant='contained' color='primary'>Continue to login</Button></Typography>
                    </form>
                </FormProvider>
        </Paper>
        </main>
    </>
  )
}

export default Signup