import React from "react";
import { Grid, Typography } from '@material-ui/core';

import Product from "./Product/Product";

import useStyles from './styles'

/*
    Handles how the products are displayed in the Storefront. For the individual products information,
    the information needed is sent to the Product.jsx file and displayed there.
*/

const Products = ({ products, onAddToCart, error, timeActive, setTimeActive, category }) => {
    const classes = useStyles();
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
        <Grid container justifyContent="center" spacing={4} style={{marginTop: "40px"}}>
            {products.filter(pro => !category.some(cat => pro.categories[0].name !== cat)).map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                    <Product product={product} onAddToCart={onAddToCart} timeActive={timeActive} setTimeActive={setTimeActive} />
                </Grid>
            ))}
        </Grid>
        <Grid>
            <Typography variant="h5" color='error' style={{ marginTop: '5%'}}>{error}</Typography>
        </Grid>
    </main>
    )
}

export default Products;