import React from 'react'
import { Container, Typography, Button, Grid} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import CartItem from './CartItem/CartItem';

/*
    This handles everythig to do with the shopping cart page, whether that be
    how the cart looks, what happens if there is nothing there, how the page 
    looks, and the logic behind it all.
*/

const Cart = ({ cart, hanldeUpdateCartQty, hanldeRemoveFromCart, hanldeEmptyCart, error }) => {
    const classes = useStyles();

    // handles when the shopping cart is empty and the user clicks onto the page
    const EmptyCart = () => (
        <Typography variant='subtitle1'>
            You have no items in your shopping cart,
            <Link to="/" className={classes.link}>start addinng some</Link>!
        </Typography>
    );

    /*
        Handles when the cart is filled and how the cart looks. It also sends all
        the information for each individual item to the CartItem.jsx file.
    */
    const FilledCart = () => (
        <>
            <main>
                <Grid container spacing={4} >
                    {cart.line_items.map((item) => (
                        <Grid item key={item.id} xs={12} sm={6} md={4} lg={4}>
                            <CartItem item={item} onUpdateCartQty={hanldeUpdateCartQty} onRemoveFromCart={hanldeRemoveFromCart}/>
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.cardDetails}>
                    <Typography variant="h4">Subtotal: {cart.subtotal.formatted_with_symbol}</Typography>
                    <div style={{marginLeft: 15}}>
                        <Button className={classes.emptyButton} size="large" type='button' variant='contained' color='secondary' onClick={hanldeEmptyCart}>Empty Cart</Button>
                        <Button component={Link} to="/checkout" className={classes.checkoutButton} size="large" type='button' variant='contained' color='primary'>Checkout</Button>
                    </div>
                </div>
                <Grid>
                    <Typography variant="h5" color='error' style={{ marginTop: '5%'}}>{error}</Typography>
                </Grid>
            </main>
        </>
    );

    if(!cart.line_items) return 'Loading...';
  return (
    <Container>
        <div className={classes.toolbar}/>
        <Typography className={classes.title} variant="h3" gutterBottom>Your Shopping Cart</Typography>
        {!cart.line_items.length ? <EmptyCart/> : <FilledCart/>}
    </Container>
  )
}

export default Cart