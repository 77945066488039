import React, { useState } from 'react';

import { Typography, List, ListItem, ListItemText, Button, TextField } from '@material-ui/core';

import { commerce } from '../../lib/commerce';

/*
  Handles everything with the review page, whether that be the look of the page, verifying promo codes, showing
  payment information, how it all works together, and the logic behind it all.
*/

const Review = ({ checkoutToken, shippingOptionName, shippingOptionPrice, setCode, code, setCheckoutToken, pushButtons }) => {
  const [errorMessage, setErrorMessage] = useState('');

  // custom error message handling to make it more user-friendly
  const modifyError = (error) => {
    if(error === 'Unsuccessful response (422: ) received')
      setErrorMessage("Promo Code is not Valid, Please Try Again.")
  }

  // tries to verify the promo code and has two options for what happens
  const verifyPromo = () => {
    // attempts to verify the discount 
    commerce.checkout.checkDiscount(checkoutToken.id, {
      code: code,
      // if the promo is valid, set's the checkout token to the new checkout token response
    }).then((response) => {setCheckoutToken(response)})
      // if something goes wrong, displays error message to the user
      .catch((error) => {modifyError(error.message)});
  }

  return (
      <>
        <Typography variant='h6' gutterBottom>Order Summary</Typography>
        <List disablePadding>
             {checkoutToken.line_items.map((product) => (
                <ListItem style={{padding: '10px 0'}} key={product.name}>
                    <ListItemText primary={product.name} secondary={ 'Quantity: ' + product.quantity} />
                    <Typography variant='body2'>{product.line_total.formatted_with_symbol}</Typography>
                </ListItem>
             ))}
             <ListItem style={{padding: '10px 0'}}>
                <ListItemText primary="Shipping" secondary={shippingOptionName} />
                <Typography variant='body2'>
                  {shippingOptionPrice}
                </Typography>
             </ListItem>
             <ListItem style={{padding: '10px 0'}}>
                <ListItemText primary="Tax" secondary="Sales Tax of Purchase" />
                <Typography variant='body2'>
                  {checkoutToken.tax.amount.formatted_with_symbol}
                </Typography>
             </ListItem>
              <ListItem style={{padding: '10px 0'}}>
                <ListItemText primary="Have Promo Code?" secondary="Only One Allowed per Order"/>
                <Typography>
                  <TextField name="promoCode" label="Enter Promo Code" value={code} onChange={(e) => setCode(e.target.value)} disabled={pushButtons}/>
                  <Button variant='contained' color='secondary' onClick={verifyPromo} disabled={pushButtons}>Verify</Button>
                </Typography>
              </ListItem>
              <ListItem style={{padding: '10px 0'}}>
                  <ListItemText/>
                  <Typography variant='body1' color='error' style={{fontWeight: 700}}>{errorMessage}</Typography>
              </ListItem>
             <ListItem style={{padding: '10px 0'}}>
                <ListItemText primary= "Total"/>
                <Typography variant='subtitle1' style={{ fontWeight: 700}} >
                    {checkoutToken.total_with_tax.formatted_with_symbol}
                </Typography>
             </ListItem>
        </List>
    </>
  )
}

export default Review