import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, IconButton, ThemeProvider, createTheme } from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';

import useStyles from './styles';

/*
  This handles what is on every product in the Storefront. It also handles when a product can be added
  to the cart.
*/

// this handles the custom theme of every product
const theme = createTheme({
    typography: {
      h5: {
        fontSize: 33,
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
      },
      h6: {
        fontSize: 23,
        fontFamily: 'sans-serif',
      },

      caption: {
        fontSize: 15,
        fontFamily: 'sans-serif',
      }, 
      
      body2: {
        fontSize: 15,
        fontFamily: 'sans-serif',
      }

    },
  });

const Product = ({ product, onAddToCart, timeActive, setTimeActive }) => {
    const classes = useStyles();
    const [time, setTime] = useState(4);

    /*
      This sets a timer that disables adding to the cart for a period of time.
      This is done so the cart can update correctly.
    */

    useEffect(() => {
        let interval = null;
        if(timeActive && time !== 0){
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        } else if(time === 0){
            setTimeActive(false)
            setTime(4)
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time, setTimeActive])

    const addToCart = () => {
      onAddToCart(product.id,1)
      .then(() => {
        setTimeActive(true)
      }).catch((err) => {
        alert(err.message)
      })
    }

    // this handles how the product looks as a whole
    return (
        <Card className={classes.root}>
            <a href={product.seo.title} target="_blank" rel='noreferrer' style={{textDecoration: 'none'}}>
              <CardMedia className={classes.media} image={product.image.url} title={product.name} style = {{ cursor: 'pointer'}} />
            </a>
            <CardContent>
                <ThemeProvider theme={theme}>
                    <div className={classes.cardContent}>
                            <Typography variant='h5' gutterBottom>
                                {product.name}
                            </Typography>
                            <Typography variant='h6'>
                                {product.price.formatted_with_symbol}
                            </Typography>
                    </div>
                    <div>
                        <Typography variant='caption'>
                                Available: {product.inventory.available}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant='caption'>
                                Category: {product.categories[0].name}
                        </Typography>
                    </div>
                    <Typography dangerouslySetInnerHTML={{ __html: product.description}} variant='body2' color="textSecondary"></Typography>
                </ThemeProvider>
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>
                <IconButton aria-label="Add to Cart" onClick={addToCart} disabled={timeActive}>
                    <AddShoppingCart />
                </IconButton>
            </CardActions>
        </Card>
  )
}

export default Product