import React, { useEffect, useState} from 'react'
import { Typography, Button, Paper } from '@material-ui/core'
import { auth, db } from '../../../lib/firebase';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { ref, onValue } from 'firebase/database';

/*
    Handles everything to do with the profile information of the user,
    whether that be the look of the page, how it works, what buttons are
    available, or the logic of it all.
*/

const Profile = ({ currentUser }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lasttName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZIP] = useState('');

    // pulls the user's data from the database and sets the state variables accordingly
    useEffect(() => {
        onValue(ref(db, 'users/' + currentUser?.uid), (snapshot) => {
            setFirstName(snapshot.val() && (snapshot.val().firstName || " "))
            setLastName(snapshot.val() && (snapshot.val().lastName || " "))
            setAddress(snapshot.val() && (snapshot.val().address || " "))
            setCity(snapshot.val() && (snapshot.val().city || " "))
            setZIP(snapshot.val() && (snapshot.val().zip || " "))
        })
    }) 

    // signs the user out and sends the user back to the ohome page
    const onSignout = () =>
    {
        signOut(auth);
        navigate("/");
    }

    // checks to see if the user is verified and returns a user-friendly message
    function isVerifiedText(){
        if (`${currentUser?.emailVerified}` === "false")
            return <Button component={Link} to="/verify-email" variant='contained' color='primary'>Verify Now</Button>
        else
            return "Yes";
    }

    return (
        <>
            <div className={classes.toolbar} />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant='h4' align='center' gutterBottom>Profile</Typography>
                    <Typography variant='subtitle1'>Email: {currentUser?.email}</Typography>
                    <Typography variant='subtitle1'>Email Verified: {isVerifiedText()}</Typography>
                    <Typography variant='subtitle1'>First Name: {firstName}</Typography>
                    <Typography variant='subtitle1'>Last Name: {lasttName}</Typography>
                    <Typography variant='subtitle1'>Address: {address}</Typography>
                    <Typography variant='subtitle1'>City: {city}</Typography>
                    <Typography variant='subtitle1'>ZIP: {zip}</Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Button component={Link} to="/" variant='contained' color='secondary'>Back to Home</Button>
                        <Button component={Link} to="/update-info" variant='contained' color='primary'>Update Information</Button>
                        <Button onClick={onSignout} variant='contained' color='secondary'>Signout</Button>
                    </div>
                </Paper>
            </main>
        
        </>
    )
}

export default Profile