import React from 'react'
import { TextField } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';

/*
    Creates a CustomTextField for the Footer.jsx file.
*/

const FormInput = ({ name, variant, label, type, value, onChange }) => {
    const { control } = useFormContext();
    return (
            <Controller
                defaultValue=""
                control={control}
                name={name}
                 
                render = {({ field }) => (
                    <TextField
                        fullWidth
                        name={name}
                        label={label}
                        type ={type}
                        value = {value}
                        onChange={onChange}
                        variant={variant}
                        required
                    />
                )}
                
            />
    )
}

export default FormInput