import React from "react";
import ReactDOM from "react-dom/client";

import App from './App';
import './index.css';

/*
    Creates the project from the root file, the App.js file.
*/

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);