import React, { useState, useEffect } from 'react';
import { commerce } from './lib/commerce';
import { auth } from './lib/firebase';
import { Products, Navbar, Cart, Checkout, Login, Signup, Profile, VerifyEmail, UpdateInfo, Footer } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { onAuthStateChanged } from 'firebase/auth';

/*
  Where all the magic happens. This is where the entire app is 
  run from and it handles routing to the different pages, setting
  timers, and more.
*/

const App = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({});
  const [order, setOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [orderErrorOccurred, setOrderErrorOccurred] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);
  const [quantityErrorMessage, setQuantityErrorMessage] = useState('');
  const [categoriesInCart, setCategoriesInCart] = useState([])
  const [category, setCategory] = useState([]);
  const [asCounter, setASCounter] = useState(0);
  const [localButtonAccess, setLocalButtonAccess] = useState(false);

  /* 
    This section is for the entire app and the refresh rate throughout.
    We want to make sure the app doesn't crash if states are messed up
    due to inactivity, so we are instituting a refresh rate to solve this
    issue. Tht refresh rate will be put to 5 minutes for now, but can be
    changed if client desires.
  */

  var refresh_rate = 300; // <- this is in seconds 
  var last_user_action = 0;
  var has_focus = false;

  function reset() {
    last_user_action = 0;
  }

  function windowHasFocus() {
    has_focus = true;
  }

  function windowLostFocus() {
    has_focus = false;
  }

  // does the countdown every second
  setInterval(function () {
    last_user_action++;
    refreshCheck();
  }, 1000);

  // check if the window needs to be reloaded
  function refreshCheck() {
    if (last_user_action >= refresh_rate && !has_focus && document.readyState === "complete") {
      window.location.reload(); // refreshes the window
      reset(); // We want to reset just to make sure the location reload is not called.
    }
  }

  // makes sure everything is being watched correctly
  window.addEventListener("focus", windowHasFocus, false);
  window.addEventListener("blur", windowLostFocus, false);
  window.addEventListener("click", reset, false);
  window.addEventListener("mousemove", reset, false);
  window.addEventListener("keypress", reset, false);
  window.addEventListener("scroll", reset, false);
  document.addEventListener("touchMove", reset, false);
  document.addEventListener("touchEnd", reset, false);


  // when someone logs in, this will set the the current user to the person who just logged in
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    })
  }, [])

  // this fetches all of the available products from Commerce JS and sets them as the products seen in the store
  const fetchProducts = async () => {
    const { data } = await commerce.products.list();
    setProducts(data);
  }

  // this will get the cart variable when needed
  const fetchCart = async () => {
    setCart(await commerce.cart.retrieve());
  }

  /*
    This function handles error checking when adding to the cart. It checks to make sure the cart categories can be added
    in the way they are, and also checks to make sure the user doesn't add more inventory than what the client has.
  */
  const handleAddQuantity = async (products, productId, quantity) => {
    // adds the item in question to the cart
    let item = await commerce.cart.add(productId,quantity);

    // gets the list of viable products
    const productsList = await commerce.products.list()
    
    // reset error message so it doesn't show the whole time
    setQuantityErrorMessage(" ")
    

    // for when adding categories that can't go together
      // go through each product individually
      for (let product of productsList.data) {
        // 4 category types: "local-store-booth", "local-and-shipping", "local-pickup", "shipping"
        // logic:
          // can't have shipping with local-pickup or local-store-booth
          // want to check and make sure the item we are adding does not conflict with the items in the cart already

        // declaration so the variable can be used elsewhere
        let catName = 0

        // create a list with the category you are adding
        if (productId === product.id){
          // gets the category name from the JSON object
          catName = product.categories[0].slug

          // make sure there is no duplicates within the list
          if (categoriesInCart.includes(catName) === false)
            // sets a list of categories to check for conflicts in the future
            setCategoriesInCart([...categoriesInCart, catName])
        }
        // if there is conflictions, don't want to add and want to remove the item and set error message
        // want to check if cat name is shipping and then check if local store booth or local pickup are in the list and then vice versa
        for (let cat of categoriesInCart) {
          if ((catName === "shipping" && (cat === "local-store-booth" || cat === "local-pickup")) || ((catName === "local-store-booth" || catName === "local-pickup") && cat === "shipping")) {
            setQuantityErrorMessage("Can Not Add Due to Conflicting Categories. Please Try Again.")

            // remove the conflict from the state
            setCategoriesInCart((prevState) => prevState.filter((prevItem) => prevItem !== catName));

            // return just has the items not used stuck in limbo until the page is refreshed or another item is added, need to remove the items being added
            for (let i of item.line_items)
              if(i.product_id === product.id)
                item = await commerce.cart.remove(i.id)
          }
        }
      }

    // for when adding to much inventory

    // go through each product
    for (let product of products) {
      // go through each item.line_item
      for (let i of item.line_items) {
        // find the product that corresponds to the cart item
        if (product.id === i.product_id){
          // check quantity of cart item compared to product
          // if going to be above, set error and update quantity
          if (i.quantity > product.inventory.available) {
            setQuantityErrorMessage("Can Not Add More Due to Limited Inventory. Please Try Again.")

            // minus the quantity by one because it is still being added by one from update
            quantity = product.inventory.available
            item = await commerce.cart.update(i.id, {quantity})
          }
        }
      }
    }
    // add the item to the cart object
    setCart(item)
}

  // sends all the necessary items to the handle function to check the validity of the add
  const handleAddToCart = async ( productId, quantity) => {
    handleAddQuantity(products, productId, quantity);
  }

  // this function handles all the checks needed when updating quantity
  const handleUpdateQuantity = async (products, productId, quantity) => {
    // updates the cart with the quantity and product provided
    let updateItem = await commerce.cart.update(productId, {quantity})

    // gets the product list
    const productsList = await commerce.products.list()

    // reset error message so it doesn't show the whole time
    setQuantityErrorMessage(' ')

    // go through each product
    for (let product of products) {
      // go through each updateItem.line_item
      for (let ui of updateItem.line_items) {
      // find the product that corresponds to the cart item
        if (product.id === ui.product_id){
          // check quantity of cart item compared to product
          // if going to be above, set error and update quantity
          if (ui.quantity > product.inventory.available) {
            setQuantityErrorMessage("Can Not Add More Due to Limited Inventory. Please Try Again.")

            // minus the quantity by one because it is still being added by one from update
            quantity = product.inventory.available
            updateItem = await commerce.cart.update(ui.id, {quantity})
          }
        }
      }
    }

    // handles the case when all are removed from the cart by the minuses
    if(updateItem.total_items === 0)
      setCategoriesInCart([]);

    // want to fire off only when the line items of both are not the same, something has been removed
    if(cart.line_items.length !== updateItem.line_items.length) {
      // go through each to find what is different
      // make temp lists to store the data
      let cartLineItemsIds = []
      let uILineItemsIds = []

      // get all of the items in 2 lists
      for (let ui of updateItem.line_items)
        uILineItemsIds.push(ui.product_id)
      for (let c of cart.line_items)
        cartLineItemsIds.push(c.product_id)

      // need to see what is different
      let difference = cartLineItemsIds.filter(x => !uILineItemsIds.includes(x))

      // find the product category that corresponds to the id and remove from list
      for (let p of productsList.data)
        if (p.id === difference[0])
          setCategoriesInCart((prevState) => prevState.filter((prevItem) => prevItem !== p.categories[0].slug));
    }
  // adds the update item to the cart object
  setCart(updateItem)
}

  // sends all the necessary items to the handle function to check the validity of the update
  const hanldeUpdateCartQty = async (productId, quantity) => {
    handleUpdateQuantity(products, productId, quantity);      
  }

  // handles everything to do with checking the validity of a remove
  const handleRemove = async (productId) => {
    // removes the item in question
    const removeItem = await commerce.cart.remove(productId);

    // gets the viable products
    const productsList = await commerce.products.list()

    // handles the case when all are removed from the cart by the minuses
    if(removeItem.total_items === 0)
      setCategoriesInCart([]);

    // want to fire off only when the line items of both are not the same, something has been removed
    if(cart.line_items.length !== removeItem.line_items.length) {
        // go through each to find what is different
        // make temp lists to store the data
        let cartLineItemsIds = []
        let rILineItemsIds = []

        // get all of the items in 2 lists
        for (let ri of removeItem.line_items)
          rILineItemsIds.push(ri.product_id)
        for (let c of cart.line_items)
          cartLineItemsIds.push(c.product_id)

        // need to see what is different
        let difference = cartLineItemsIds.filter(x => !rILineItemsIds.includes(x))

        // find the product cat that corresponds to the id and remove from list
        for (let p of productsList.data)
          if (p.id === difference[0])
            setCategoriesInCart((prevState) => prevState.filter((prevItem) => prevItem !== p.categories[0].slug));
      }
    // removes that item from the cart object
    setCart(removeItem)
  }

  // sends all the necessary items to the handle function to check the validity of the remove
  const hanldeRemoveFromCart = async (productId) => {
    handleRemove(productId);
  }

  // sets the cart object to empty and clears the categories in cart
  const hanldeEmptyCart = async () => {
    const emptyCart = await commerce.cart.empty();
    setCart(emptyCart);
    setCategoriesInCart([]);
  }

  // sets the cart object to a new object and resets the order
  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();
    setCart(newCart);
    setOrder({});
  }

  // takes the checkout token id and order and sets the order based on that
  const hanldeCaptureCheckout = async (checkoutTokenId, newOrder) => {
    try {
      const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);
      setOrder(incomingOrder);
    } catch (error) {
      setErrorMessage(error.data.error.message);
      setOrderErrorOccurred(true);
    }
  }

  // gets the products and cart on every render
  useEffect(() => {
    fetchProducts();
    fetchCart();
  }, []);

  // Check to see if the local button can be accessed
  useEffect(() => {
    for (var cartCat of categoriesInCart) {
      // want to check if it not 'shipping'
      // also want to check if local and shipping and then if shipping is also present
      if (cartCat === 'shipping' || (categoriesInCart.includes('shipping') && categoriesInCart.includes('local-and-shipping')))
        setLocalButtonAccess(true)
      // need to reset on the chance the button access was set to true
      else
        setLocalButtonAccess(false)
    }
  }, [categoriesInCart])

  // the return contains all the routes and their arguments to the different pages
  return (
    <Router>
      <div>
        <AuthProvider value={{currentUser}}>
          <Navbar totalItems={cart.total_items} currentUser={currentUser} products={products} category={category} setCategory={setCategory} asCounter={asCounter}/>
          <Routes>
            <Route path='/' element={<Products products={products} onAddToCart={handleAddToCart} error={quantityErrorMessage} timeActive={timeActive} setTimeActive={setTimeActive} category={category} />} />
            <Route path='/cart' element={<Cart cart={cart} hanldeUpdateCartQty={hanldeUpdateCartQty} hanldeRemoveFromCart={hanldeRemoveFromCart} hanldeEmptyCart={hanldeEmptyCart} error={quantityErrorMessage}/>} />
            <Route path='/checkout'  element={<Checkout cart={cart} order={order} onCaptureCheckout={hanldeCaptureCheckout} error={errorMessage} currentUser={currentUser} refreshCart={refreshCart} setASCounter={setASCounter} localButtonAccess={localButtonAccess} orderErrorOccurred={orderErrorOccurred}/>}/>
            <Route path='/login' element={<Login timeActive={timeActive} setTimeActive={setTimeActive} />}/>
            <Route path='/signup' element={<Signup auth={auth} timeActive={timeActive} setTimeActive={setTimeActive} />}/>
            <Route path='/profile' element={<Profile currentUser={currentUser}/>}/>
            <Route path='/verify-email' element={<VerifyEmail currentUser={currentUser} timeActive={timeActive} setTimeActive={setTimeActive}/>}/>
            <Route path='/update-info' element={<UpdateInfo currentUser={currentUser}/>}/>
          </Routes>
          <Footer/>
        </AuthProvider> 
      </div>
    </Router>
  )
}

export default App