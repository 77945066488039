import React from 'react';
import { AppBar, Toolbar, IconButton, Badge, Typography} from '@material-ui/core';
import { ShoppingCart, AccountCircle, Home } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import logo  from '/Users/masonschenk/Documents/BYUI/BYUI Winter 2023/Senior Project Part A/CodeSpace/iris/src/assets/red blog girl .png';
import header from '/Users/masonschenk/Documents/BYUI/BYUI Winter 2023/Senior Project Part A/CodeSpace/iris/src/assets/scc_logo.png';
import honeyBee from '/Users/masonschenk/Documents/BYUI/BYUI Winter 2023/Senior Project Part A/CodeSpace/iris/src/assets/honeyBee.png';
import hylaBrook from '/Users/masonschenk/Documents/BYUI/BYUI Winter 2023/Senior Project Part A/CodeSpace/iris/src/assets/HylaBrook Market Logo.png';
import useStyles from './styles'
import Dropdown from './Dropdown';

/*
    Handles everything to do with the Navbar, and the different states of the Navbar throughout
    the project. If statements work in the return per it being rerendered every render of the
    website.
*/
const Navbar = ({ totalItems, currentUser, products, category, setCategory, asCounter }) => {
    const classes = useStyles();
    const location = useLocation();
    return (
        <>
        {/* Don't want the navbar shown on the confirmation screen */}
        {asCounter !== 1 && (
            <AppBar position="fixed" className={classes.appBar}  color="inherit" style={{maxWidth: '100vw', overflowX: 'hidden'}}>
                <Toolbar style={{display: 'flex', flexWrap:'wrap'}}>
                    <img src={logo} alt="She's Crafty Crafty" height="25px" className={classes.image} />
                    <Typography component={Link} to="https://www.shescraftycrafty.com/" target="_blank" rel='noreferrer'>
                        <img src={header} alt="Website Header" height="50px" width="170px" style={{marginTop: "15px"}} className={classes.image}/>
                    </Typography>
                    <div className={classes.button}>
                        <IconButton component={Link} to="https://www.honeybeeantiquemarket.com/" target="_blank" rel='noreferrer'>
                            Honey Bee Antique Market
                            <img src={honeyBee} alt="Website Header" height="30px" width="30px" style={{marginTop: "5px"}} className={classes.image}/>
                        </IconButton>
                        <IconButton component={Link} to="https://www.facebook.com/hylabrook.antiquemall?mibextid=LQQJ4d" target="_blank" rel='noreferrer'>
                            Hylabrook Antique Mall
                            <img src={hylaBrook} alt="Website Header" height="30px" width="30px" style={{marginTop: "7px"}} className={classes.image}/>
                        </IconButton>
                    </div>
                    <div className={classes.grow}/>
                    {/* Only show the profile and cart pages on home screen  */}
                    {location.pathname === '/' && (
                    <div className={classes.button} style={{left: '0px'}}>
                        <IconButton component={Link} to={currentUser == null ? "/login" : "/profile"} aria-label='Go to account page' color='inherit' >
                            <AccountCircle/>
                        </IconButton>
                        <IconButton component={Link} to="/cart" aria-label="Show cart items" color="inherit" >
                            <Badge badgeContent={ totalItems } color="secondary">
                                <ShoppingCart />
                            </Badge>
                        </IconButton>
                        <Dropdown products={products} category={category} setCategory={setCategory}/>
                    </div> )}
                    {/* Only show the home button when not on the home screen */}
                    {!(location.pathname === '/') && (
                    <div className={classes.button} style={{left: '0px'}}>
                        <IconButton component={Link} to="/" aria-label='Go to home screen' color='inherit'>
                            <Home/>
                        </IconButton>
                    </div> )}
                </Toolbar>
            </AppBar>
        )}
        </>
    )
}

export default Navbar