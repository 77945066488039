import React, { useState } from 'react'
import { Typography, IconButton, Button, Grid, Paper } from '@material-ui/core'
import { Visibility } from '@material-ui/icons';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../lib/firebase';
import { useForm, FormProvider} from 'react-hook-form';
import FormInput from '../CustomTextField';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './styles';

/*
    Handles everything to do with the Login on the website, whether that
    be how it looks, how it works, or the logic behind it.
*/

const Login = () => {
    const methods = useForm();
    const classes = useStyles();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [toggle, setToggle] = useState(true);
    const [em, setEm] = useState('');
    const [resetEmailMessage, setResetEmailMessage] = useState(' ');

    // custom error message handling to make it more user-friendly
    const getError = (errorMessage) => {
        if(errorMessage === 'Firebase: Error (auth/invalid-email).')
            setEm("Invalid Email: Please Enter a Valid Email.")
        else if (errorMessage === 'Firebase: Error (auth/wrong-password).')
            setEm("Invalid Password: Please Enter A Valid Password.")
        else if (errorMessage === 'Firebase: Password should be at least 6 characters (auth/weak-password).')
            setEm("Invalid Password: Password Should be at Least 6 Characters.")
        else
            setEm("Error: Please Try Again.")
    }

    // handles what happens when the user pushes the "Login" button
    const onLogin = (e) => {
        e.preventDefault();

        // attempts to sign the user in with the email and password created by the user
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // if log in is successful, redirects to the homepage
                navigate("/");
            })
            // if something goes wrong, the error is displayed to the user
            .catch((error) => {
                const errorMessage = error.message;
                getError(errorMessage);
            })
    }

    // set's the password to visible or not visbile, based on what the user wants
    const toggler = () => {
        toggle ? setToggle(false) : setToggle(true)
    }

    // handles when the user forget's their password
    const triggerResetEmail = async (email) => {
        await sendPasswordResetEmail(auth, email);
        setResetEmailMessage("Reset Email has Been Sent")
    }

    
  return (
    <>
        <div className={classes.toolbar} />
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography variant='h4' align='center' gutterBottom>Login</Typography>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((data)=> ({ ...data}))}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid container item spacing={3} justifyContent="center">
                                <FormInput name='email' label='Email address' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </Grid>
                            <Grid container item spacing={3} justifyContent="center">
                                <IconButton disabled='true'/>
                                <IconButton disabled='true'/>
                                <FormInput name='password' label='Password' type= {toggle ? 'password' : " "} value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <IconButton onClick={toggler}>
                                    <Visibility/>
                                </IconButton>
                            </Grid>
                            <Grid>
                                <Typography color='error'>{em}</Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <Button component={Link} to="/" variant="contained" color='secondary'>Back to Home </Button>
                            <Button onClick={onLogin} variant="contained" color='primary'>Login</Button>
                        </div>
                        <div style={{ justifyContent: 'space-between'}}>
                            <Typography variant='h6' align='center' gutterBottom>Haven't made an account?</Typography>
                            <Typography align='center' style={{marginBottom: '30px'}}><Button component={Link} to='/signup' variant='contained' color='primary'>Create one Now!</Button></Typography>
                        </div>
                        <div>
                            <Typography variant='h6' align='center' >Forgot Password?</Typography>
                            <Typography variant='h6' align='center' gutterBottom>Enter your email and push the button below</Typography>
                            <Typography align='center' style={{marginBottom: '30px'}}><Button onClick={() => {triggerResetEmail(email)}} variant='contained' color='primary'>Reset Password</Button></Typography>
                            <Typography color='primary' align='center' variant='h6'><b>{resetEmailMessage}</b></Typography>
                        </div>
                    </form>
                </FormProvider>
            </Paper>
        </main>    
    </>
  )
}

export default Login