import React from 'react'
import { TextField, Grid } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';

/*
    Creates a CustomTextField for the Checkout Folder.
*/

const FormInput = ({ name, label, value, set }) => {
    const { control } = useFormContext();
    const onc = e => set(e.target.value);
    return (
        <Grid item xs={12} sm={6}>
            <Controller
                control={control}
                name={name}
                render = {({ field }) => (
                    <TextField
                        defaultValue= " "
                        fullWidth
                        label={label}
                        required
                        value = {value}
                        onChange={onc}
                    />
                )}
                
            />

        </Grid>
    )
}

export default FormInput